
  import { Component, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { ConceptModel } from '@/models';
  import RestaurantStatusSwitch from '@/components/RestaurantStatusSwitch.vue';
  import TimeIcon from '@/components/TimeIcon.vue';
  import { toLocale } from '@/helpers/date';

  const conceptsStore = namespace('concepts');

  @Component({ components: { RestaurantStatusSwitch, TimeIcon } })
  export default class ConceptsPage extends Vue {
    @conceptsStore.State('conceptsForUser')
    readonly concepts!: ConceptModel[];

    getTurnedOffUntil(concept: ConceptModel): string | null {
      if (!concept.storeStatus) {
        return null;
      }

      const until = Object.values(concept.storeStatus)?.[0]?.until;
      return until ? toLocale(until) : null;
    }
  }
